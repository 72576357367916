import React from 'react';
import { createUseStyles } from 'react-jss';

import { tags, queryDesktop } from '../utils/constants.mjs';
import useWindowSize from '../utils/windowHooks';

import Tag from './Tag';

const useStyles = createUseStyles({
  tagBoxSection: {
    width: '100%',
  },
  tagBox: {
    border: 'solid 2px black',
    padding: 10,
  },
  tagBoxText: {
    fontWeight: 600,
  },
  [queryDesktop]: {
    tagBoxSection: {
      position: 'absolute',
      left: (props) => props.width / 2 - 500,
      width: 140,
      textAlign: 'center',

      '& a': {
        display: 'block',
        width: 80,
        margin: '0 auto 5px',

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
});

const TagBox = ({ hasReset }) => {
  const [width] = useWindowSize();
  const classes = useStyles({ width });

  return (
    <section className={classes.tagBoxSection}>
      <span className={classes.tagBoxText}>Tags</span>
      <div className={classes.tagBox}>
        {Object.keys(tags).filter((tag) => (hasReset ? true : tag !== 'reset')).map((tagType) => (
          <Tag key={tagType} tagType={tagType} />
        ))}
      </div>
    </section>
  );
};

export default TagBox;

import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { useQueryParam, StringParam } from 'use-query-params';
import {
  isAuthenticated,
} from '../utils/auth';

import Bio from '../components/Bio';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { rhythm } from '../utils/typography';
import Button from '../components/Button';
import TagBox from '../components/TagBox';
import { H3 } from '../components/typography/Heading';

const isBrowser = typeof window !== 'undefined';

const Blog = (props): JSX.Element => {
  if (!isBrowser) return null;

  const { data } = props;
  const pageTitle = 'Sacha Morgese\'s Blog';
  const posts = data.allMdx.edges;
  const [tag] = useQueryParam('tag', StringParam);

  return (
    <Layout location={props.location} title={pageTitle}>
      <Seo
        title="All posts"
        description="All blog posts"
        keywords={['sacha morgese\'s blog', 'sacha morgese blog', 'sacha morgese']}
      />
      <Bio />
      <TagBox hasReset={!!tag} />
      <div style={{ margin: '20px 0 40px' }}>
        {posts
          .filter(({ node }) => {
            if (!isAuthenticated()) {
              return node.fields.collection === 'blog';
            }
            return true;
          })
          .filter(
            ({
              node: {
                frontmatter: { tags },
              },
            }) => tags.split(', ').includes(tag) || !tag,
          )
          .map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <div key={node.fields.slug}>
                <H3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link
                    style={{ boxShadow: 'none' }}
                    to={`/blog${node.fields.slug}`}
                  >
                    {title}
                  </Link>
                </H3>
                <small>{node.frontmatter.date}</small>
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
                />
              </div>
            );
          })}
      </div>
      <Link to="/">
        <Button marginTop="85px">Go Home</Button>
      </Link>
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query BlogPage {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            collection
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`;
